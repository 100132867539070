//**************************************************************
// 1. scroll fadein animation
//**************************************************************
$(window).scroll(function () {
  $('.fadeIn, .fadeInUp, .fadeInRight, .fadeInLeft, .fadeInScale').each(function() {
    var elemPos = $(this).offset().top,
      scroll = $(window).scrollTop(),
      windowHeight = $(window).height();

    if (scroll > elemPos - windowHeight + 100) {
      $(this).addClass('scrollin');
    }
  });
});


//**************************************************************
// 2. Smooth Scroll （TOPに戻るボタン用）
//**************************************************************
$(function(){
  $('a[href^="#"]').click(function(){
    var speed = 500;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("html, body").animate({scrollTop:position}, speed, 'linear');
    return false;
  });
});


//**************************************************************
// 3. スクロールしたら メニューを隠す
//**************************************************************
var startPos = 0,winScrollTop = 0;
$(window).on('scroll',function(){
    winScrollTop = $(this).scrollTop();
    if (winScrollTop >= startPos) {
        if(winScrollTop >= 200){
            $('.site-header').addClass('end-up');
        }
    } else {
        $('.site-header').removeClass('end-up');
    }
    startPos = winScrollTop;
});


//**************************************************************
// 4. スプラッシュアニメーションのトリガー
//**************************************************************
$(window).on('load',function(){
    $("#splash-logo").delay(1000).fadeOut('slow');//ロゴを1.2秒でフェードアウトする記述
    //=====ここからローディングエリア（splashエリア）を1.5秒でフェードアウトした後に動かしたいJSをまとめる
    $("#splash").delay(1300).fadeOut('slow',function(){//ローディングエリア（splashエリア）を1.5秒でフェードアウトする記述

        $('body').addClass('appear');//フェードアウト後bodyにappearクラス付与
    });
    //=====ここまでローディングエリア（splashエリア）を1.5秒でフェードアウトした後に動かしたいJSをまとめる

   //=====ここから背景が伸びた後に動かしたいJSをまとめたい場合は
    $('.splashbg').on('animationend', function() {
      var element = $(".typing");
      var thisChild = ""
      element.each(function () {
        var text = $(this).html();
        var textbox = "";
        text.split('').forEach(function (target) {
          if (target !== " ") {
            textbox += '<span>' + target + '</span>';
          } else {
            textbox += target;
          }
        });
        $(this).html(textbox);
      });
      
      // タイピングアニメーション
       $(element).each(function () {
          thisChild = $(this).children(); //生成したspanタグを取得
          thisChild.each(function (i) {
            var time = 100;
            $(this).delay(time * i).fadeIn(time);
          });
      });
    });
    //=====ここまで背景が伸びた後に動かしたいJSをまとめる
});


//**************************************************************
// アコーディオン
//**************************************************************
//アコーディオンをクリックした時の動作
$('.ac-box').on('click', function() {//タイトル要素をクリックしたら
  var findElm = $(this).next(".box");//直後のアコーディオンを行うエリアを取得し
  $(findElm).slideToggle();//アコーディオンの上下動作

  if($(this).hasClass('close')){//タイトル要素にクラス名closeがあれば
    $(this).removeClass('close');//クラス名を除去し
  }else{//それ以外は
    $(this).addClass('close');//クラス名closeを付与
  }
});

//**************************************************************
// 5. ナノタン夏or冬 切り替えボタン
//**************************************************************
$(function() {
	$('.switch-btn').click(function() {
		var index = $('.switch-btn').index(this);
		$('.nanotan-visual').css('display','none');
		$('.nanotan-visual').eq(index).fadeIn("slow");
		$('.switch-btn').removeClass('active');
		$(this).addClass('active')
	});
});


//**************************************************************
// 5. 実績画像の切り替え
//**************************************************************
$(function() {
  var pcDesign = $('.pc-design');
  var spDesign = $('.sp-design');

  $('#pc-design-btn').click(function() {
    $(this).addClass('active');
    $('#sp-design-btn').removeClass('active');
    pcDesign.fadeIn("slow");
    spDesign.hide();
  });
  $('#sp-design-btn').click(function() {
    $(this).addClass('active');
    $('#pc-design-btn').removeClass('active');
    spDesign.fadeIn("slow");
    pcDesign.hide();
  });
});



//**************************************************************
// 5. タイピングアニメ〜ション
//**************************************************************
$(window).on('load',function(){
  var element = $(".typing");
  var thisChild = ""
  element.each(function () {
    var text = $(this).html();
    var textbox = "";
    text.split('').forEach(function (target) {
      if (target !== " ") {
        textbox += '<span>' + target + '</span>';
      } else {
        textbox += target;
      }
    });
    $(this).html(textbox);
  });
  
  // タイピングアニメーション
   $(element).each(function () {
      thisChild = $(this).children(); //生成したspanタグを取得
      thisChild.each(function (i) {
        var time = 100;
        $(this).delay(time * i).fadeIn(time);
      });
  });
});