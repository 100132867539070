$(document).ready(function() {

  //----------------------------------------------------
  // ページ離脱確認
  //----------------------------------------------------
  var isChanged = false;  // フォームの入力欄が更新されたかどうかを表すフラグです。

  $(window).on("beforeunload", function() {
    if(isChanged) {
      return "このページを離れると、入力したデータが削除されます。";
    }
  });

  $("form input, form select, form textarea").change(function() {
    // 入力内容が更新されている場合は、isChangedフラグをtrueにします。
    isChanged = true;
  });


  //----------------------------------------------------
  // submit実行
  //----------------------------------------------------
  $("#contact-submit").click(function() {
    // ページ遷移拒否を無効
    isChanged = false;
    $(window).off('beforeunload');
  });


  //----------------------------------------------------
  // 確認処理
  //----------------------------------------------------
  $("#contact-confirm").on("click", function() {
    let inquiry_type = [];
    $("input[name='inquiry-type[]']:checked").each(function() {
      inquiry_type.push($(this).val());
    });
    // console.log(inquiry_type);
    let name = $("input[name='name']").val();
    let email = $("input[name='email']").val();
    let message = $("textarea[name='message']").val();

    // もし、各入力欄が空白だった場合、.err をゆっくりと表示する
    if (name == "" || email == "" || message == "") {
      $(".err").fadeIn('slow');
      return false;
    }
    $(".err").hide();

    // 問い合わせの種類を文字列に、そして、規定の文字で区切る
    let inquiry_type_str = inquiry_type.join(" , ");

    // 確認フェーズ用のdivのpに値を挿入
    $("#confirm-name p").text(name); // name
    $("#confirm-email p").text(email); // email
    message = message.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&#39;').replace(/`/g, '&#x60;').replace(/\r?\n/g,'<br />');
    $("#confirm-message p").html(message); // message

    // 入力エリアは隠して、確認エリアは表示する
    $(".input-area, .privacy-policy, .bmd-help, .confirm-btn").hide();
    $(".confirm-area").fadeIn('slow');
  });
  $("#contact-return").on("click", function() {
    $(".confirm-area").hide();
    $(".input-area, .bmd-help, .privacy-policy, .confirm-btn").fadeIn('slow');
  });


  //----------------------------------------------------
  // contact form
  //----------------------------------------------------
  (function($) {
    $(function() {
      $('#contact-form').submit(function(event){
        "use strict";
        event.preventDefault(); // submit無効化

        let url = $(this).data('url');
console.log(decodeURI($(this).serialize()));
        $.ajax({
          type: 'POST',
          url: url,
          dataType: 'text',
          data: {
              'action' : 'post_contact',
              'params[]' : $(this).serialize(),
          },
          // data: $(this).serialize()

        }).done(function(data, textStatus, jqXHR) {
          console.log(data);
          // console.log(textStatus);
          // console.log(jqXHR);
          let param = JSON.parse(data);
          if (param.code === 200) { // success
            $('#contact-form').remove();
            $('#contact-thanks').removeClass('d-none');
          } else {
            $('#contact-thanks').removeClass('d-none');
            $('#result-contact p').addClass('err');
          }

          $('#result-contact p').html(param.message);

        }).fail(function() {
          $('#result-contact').addClass('err');
          $('#result-contact').html('通信エラーが起こりました。お手数ですが、もう一度やり直すか直接メール（contact@nanofun.co.jp）にてお問い合わせください。');
        });
      });
    })
  })(jQuery);

});
